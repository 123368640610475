import React from "react";
import style from "./NavBar.module.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import stackviz from "../../Images/stackviz.png";
import Accordion from "react-bootstrap/Accordion";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";

function NavBarMain() {
  if (true) {
    var x = "hlle"
    let y="seci"
  }
  console.log(x)

  return (
    <>
      <Navbar expand="lg" className={style.mainNavHead}>
        <Container className={style.navHead}>
          <Navbar.Brand href="#home" className={style.navLogo}>
            <Link to="/">
              <div>
                <img className={style.mainLogo} src={stackviz} />
              </div>
            </Link>
          </Navbar.Brand>
          {/* <button className={style.ToggleBookCallButton}>BOOK A CALL</button> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className={style.CollapseStyle}
            id="basic-navbar-nav"
          >
            {/* <Nav cla > */}
            {/* <NavDropdown className={style.TogglerIcon}  title="OUR SERVICES" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item  href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
            <div className={style.navRight}>
              <Nav.Link className={style.TogglerIcon}>
                OUR SERVICES
                <RiArrowDropDownLine size={25} />
                <div className={style.dropDown}>
                  <div style={{ paddingBottom: 10 }}>Product Strategy</div>
                  <div style={{ paddingBottom: 10 }}>Product Design</div>
                  <div style={{ paddingBottom: 10 }}>Product Development</div>
                  <div style={{ paddingBottom: 10 }}>Product Launch</div>
                  <div style={{ paddingBottom: 10 }}>Product Growth</div>
                  <div>Product Data Management</div>
                </div>
              </Nav.Link>
              <Nav.Link href="#Portfolio" className={style.SecTogglerIcon}>
                PORTFOLIO
              </Nav.Link>
              {/* <Nav.Link className={style.SecTogglerIcon} >BLOG</Nav.Link> */}
              {/* <a href="#Click">
                <button className={style.bookCallButton}>BOOK A CALL</button>
              </a> */}
            </div>
            {/* </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar expand="lg" className={style.MobResNAv}>
        <Container className={style.MobNavSize}>
          <Navbar.Brand href="#home" className={style.navLogo}>
            <div>
              <img className={style.mainLogo} src={stackviz} />
            </div>
          </Navbar.Brand>
          <a href="#Click" style={{ textDecoration: "none" }}>
            <button className={style.ToggleBookCallButton}>BOOK A CALL</button>
          </a>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className={style.ToggleIcon}
          />
          <Navbar.Collapse id="basic-navbar-nav" className={style.NavColor}>
            <Nav className="me-auto navWidth">
              {/* <NavDropdown title="OUR SERVICES" id="basic-nav-dropdown"> */}
              {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
               OUR SERVICES
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className={style.AccordinMain}>
                  <Accordion.Header className={style.AccordionHeading}>
                    OUR SERVICES
                  </Accordion.Header>
                  <Accordion.Body className={style.AccordionBody}>
                    Product Strategy
                  </Accordion.Body>
                  <Accordion.Body className={style.AccordionBody}>
                    Product Design
                  </Accordion.Body>
                  <Accordion.Body className={style.AccordionBody}>
                    Product Development
                  </Accordion.Body>
                  <Accordion.Body className={style.AccordionBody}>
                    Product Launch
                  </Accordion.Body>
                  <Accordion.Body className={style.AccordionBody}>
                    Product Growth
                  </Accordion.Body>
                  <Accordion.Body className={style.AccordionBody}>
                    Product Data Management
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* </NavDropdown> */}
              <Nav.Link href="#home" className={style.LinkColor}>
                PORTFOLIO
              </Nav.Link>
              {/* <Nav.Link href="#link" className={style.LinkColor}>BLOG</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBarMain;
