import React from 'react'
import style from "./FirstSection.module.css"
import mainImg from '../../Images/imgesection.png'
import coment from '../../Images/coment.png'
import ratting from '../../Images/ratting.png'
import Trusted from '../../Images/Trusted.png'
import Coconut from '../../Images/Coconut.png'
import eCom from '../../Images/eCom.png'
import last from '../../Images/last.png'
import Tupper from '../../Images/Tupper.png'

import { FaBezierCurve } from 'react-icons/fa';

import { FaFlask } from 'react-icons/fa';
import { SiAltiumdesigner } from 'react-icons/si';
import { MdDeveloperMode } from 'react-icons/md';
import { SiMarketo } from 'react-icons/si';

function FirstSection() {
  return (
    <>
    <div className={style.SecSection}>
    <div className={style.SecTextImg}>
        <div className={style.SecText}>

            <div className={style.SecHeading}>
               We Provide Web and Mobile app 
               {/* <span style={{ color: '#0700af' }}>Into Gold</span> */}
            </div>
        
            <div className={style.SecIconHeading}>
                <FaBezierCurve className={style.frstIcon}/>
                Stand out with a  <span style={{ color: '#050133',fontWeight:'700' }}> Research</span> 
            </div>
            <div className={style.SecIconHeading}>
                <SiAltiumdesigner className={style.frstIcon}/>
                Develop best <span style={{ color: '#050133',fontWeight:'700' }}>  Design</span> 
            </div>
            <div className={style.SecIconHeading}>
            <MdDeveloperMode  className={style.frstIcon}/>
            Create  best   <span style={{ color: '#050133',fontWeight:'700' }}> Development</span>
            </div>
            <div className={style.SecIconHeading}>
            <SiMarketo className={style.frstIcon}/>
                Provide <span style={{ color: '#050133',fontWeight:'700' }}> Marketing Services</span>
            </div>

        </div>
        <div className={style.SecImg}>
            <button className={style.discoverCallButton}>
                Book a Discovery Call
            </button>
            {/* <img className={style.MainImg} src={mainImg} /> */}
        </div>

    </div>





</div>
    <div className={style.MainSec}>
        <div className={style.innerSec}>
            <img src={coment}/>
        <div className={style.Data}>
            <div className={style.Name}>
                Cory
            <img style={{marginLeft:'20px'}} src={ratting}/>
            </div>

            <div>CEO of RoodiePet</div>
        </div>
        <div className={style.Desc}>
        “You can’t beat this combination of price and quality and I really think that we have a great design that will boost our conversions and put more hard earned money back in our business“
        </div>
        </div>
    </div>

    <div className={style.Row}>
        <div className={style.rowsSection}>
            <div className={style.rowFirstImg}>
                <img src={Trusted} />
            </div>
            <div className={style.imgInner}>
            <div className={style.rowFirstImg}>
                <img className={style.ImgSize} src={Tupper} />
            </div>
            <div className={style.rowFirstImg}>
                <img className={style.ImgSize}  src={Coconut} />
            </div>
            <div className={style.rowFirstImg}>
                <img className={style.ImgSize}  src={eCom} />
            </div>
            <div className={style.rowFirstImg}>
                <img className={style.ImgSize}  src={last} />
            </div>  
            </div>  
        </div>
    </div>
    </>
  )
}

export default FirstSection
