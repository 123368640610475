import logo from "./logo.svg";
import "./App.css";
import NavBarMain from "./Components/NavBar/NavBarMain";
import MainPage from "./Components/MainPage/MainPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Portfolios from "./Components/Portfolios/Portfolios";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./Components/TermsCondition/TermsCondition";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <NavBarMain/> */}
          <Route path="/" element={<MainPage />} />
          <Route path="/Portfolio" element={<Portfolios />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termscondition" element={<TermsCondition />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
