import React from 'react'
import style from "./RocketPage.module.css"
import { BsCircleFill } from 'react-icons/bs';
import RocketBack from '../../Images/RocketBack.png'
import RocketMain from '../../Images/RocketMain.png'
import Rocket from '../../Images/rocketLogo.png'
import Image from '../../Images/mobimg.jpg'
import Image1 from '../../Images/webimg.jpg'
import Image2 from '../../Images/dataimg.jpg'

function RocketPage() {
  return (
    <div className={style.MainSecRocket}>
      <div className={style.mainsec}>
      <div className={style.main1}>
      <div className={style.heading1}>
        Mobile Apps
      </div>
      <div className={style.image1}>
        <img src={Image} alt="" className={style.img1} />
      </div>
      </div>
      <div className={style.line}></div>
      <div className={style.main2}>
      <div className={style.heading1}>
        Web Apps
      </div>
      <div className={style.image1}>
        <img src={Image1} alt="" className={style.img1} />
      </div>
      </div>
      <div className={style.line}></div>
      <div className={style.main3}>
      <div className={style.heading1}>
      Data Services
      </div>
      <div className={style.image1}>
        <img src={Image2} alt="" className={style.img1} />
      </div>
      </div>
      </div>
      {/* <div className={style.Firstbutton}>
        <a href='#Click' className={style.MainbuttonSec}>
          <button className={style.discoverCallButton}>
          BOOK A DISCOVERY CALL
          </button>
          </a>
     </div> */}
    </div>
  )
}

export default RocketPage
