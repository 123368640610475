import React from "react";
import style from "./Footer.module.css";
import Logo from "../../Images/stackviz.png";
import {
  FaInstagram,
  FaRegEnvelope,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { TbPhone } from "react-icons/tb";
import { HiLocationMarker } from "react-icons/hi";
import { BsFacebook, BsTwitter, BsLinkedin, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={style.MainFooter}>
      <div className={style.FooterRow}>
        <div className={style.FirstColoumn}>
          <img className={style.LogoImgSize} src={Logo} />

          <div className={style.ContactDet}>
            Email :
            <div className={style.Email}>
              <FaRegEnvelope className={style.mailIcon} size={25} />
              info@stackviz.com
            </div>
            <div className={style.ContactDet}>
              Phone Number :
              <div className={style.Email}>
                <TbPhone className={style.mailIcon} size={25} />
                +1 281 407 1887
              </div>
              <div className={style.ContactDet}>
                Address :
                <div className={style.Email}>
                  <HiLocationMarker className={style.mailIcon} size={25} />
                  1708 Spring Green Blvd Ste 120, Katy TX 77494
                </div>
              </div>
            </div>
            <div className={style.IconsSection}>
              <a
                href="https://www.facebook.com/profile.php?id=100089872604920"
                target="_blank"
              >
                <BsFacebook size={30} color="white" />
              </a>

              <a
                href="https://www.instagram.com/stackviz_official/"
                target="_blank"
              >
                {" "}
                <FaInstagram size={30} color="white" />
              </a>

              <a href="https://www.tiktok.com/@stackviz " target="_blank">
                <FaTiktok size={30} color="white" />
              </a>
              <a
                href="https://www.youtube.com/@stackviz_official"
                target="_blank"
              >
                <FaYoutube size={30} color="white" />
              </a>
            </div>
          </div>
        </div>
        <div className={style.QuickLinks}>
          <div className={style.Size}>Quick Links </div>

          <div className={style.ContactDet}>
            <Link
              to="/termscondition"
              style={{ textDecoration: "none" }}
              onClick={scrollToTop}
            >
              <div className={style.linkText}> Terms of Services </div>
            </Link>
            <Link
              to="/PrivacyPolicy"
              style={{ textDecoration: "none" }}
              onClick={scrollToTop}
            >
              <div className={style.linkText}> Privacy Policy</div>
            </Link>

            <div> Blog</div>
          </div>
        </div>
        {/* <div className={style.QuickLinks}>
            <div className={style.Size}>Our Address </div> 
            <div  className={style.ContactSect} >
            <HiLocationMarker size={25}/>
                <div className={style.LocData}>1708 Spring Green Blvd Ste 120, Katy TX 77459</div>
                </div>    
            </div> */}
      </div>
      <div className={style.CopyRight}>
        Copyright ©2022 StackViz. All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
