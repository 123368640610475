import React from 'react'
import styles from '../PrivacyPolicy/PrivacyPolicy.module.css'
import NavBarMain from '../NavBar/NavBarMain'
import Footer from '../Footer/Footer'
const PrivacyPolicy = () => {
  return (
    <>
    <NavBarMain/>
    <div className={styles.privacyMain}>
      <div className={styles.privacyContainer}>
        <div className={styles.privacyMainheading}>
        Privacy Policy
        </div>
        <div className={styles.privacyMainheading2}>
        Last Updated: 10/28/2023
        </div>
        <div className={styles.privacyMaintext}>
        Stackviz is committed to protecting the privacy and security of your personal information. This Privacy 
Policy describes how we collect, use, disclose, and protect the personal information you provide to us 
when you visit our website or use our services. By using our website and services, you consent to the 
practices described in this Privacy Policy.
        </div>

        <div className={styles.privacyheading1}>
        1. Information We Collect
        </div>
        <div className={styles.privacytext1}>
        We may collect the following types of personal information:
        </div>
        <div className={styles.privacytext1}>
        1.1 Information You Provide: We collect personal information you provide when you interact with our 
website, contact us, or use our services. This may include your name, email address, phone number, job 
title, and any other information you choose to share.
        </div>
        <div className={styles.privacytext1}>
        1.2 Automatically Collected Information: We may collect certain information automatically when you 
visit our website, including your IP address, device type, browser type, and cookies. We use cookies to 
enhance your experience and collect aggregate data for analytics.
        </div>
        <div className={styles.privacyheading1}>
        2. How We Use Your Information

        </div>
        <div className={styles.privacytext1}>
        We may use your personal information for the following purposes:
        </div>
        <div className={styles.privacytext1}>
        2.1 Providing Services: To provide the services you request, communicate with you, and fulfill our 
contractual obligations.
        </div>
        <div className={styles.privacytext1}>
        2.2 Improving our Services: To analyze and improve our services, website, and user experience.
        </div>
        <div className={styles.privacytext1}>
        2.3 Marketing: To send you marketing communications if you have provided your consent, and to 
customize and personalize your experience
        </div>
        <div className={styles.privacytext1}>
        2.4 Compliance: To comply with legal and regulatory requirements.
        </div>
        <div className={styles.privacyheading1}>
        3. Sharing Your Information
        </div>
        <div className={styles.privacytext1}>
        We do not sell your personal information to third parties. We may share your information with:
        </div>
        <div className={styles.privacytext1}>
        3.1 Service Providers: We may share information with third-party service providers who assist us in 
delivering our services.
        </div>
        <div className={styles.privacytext1}>
        3.2 Legal Compliance: We may disclose your information if required by law or to protect our rights, 
privacy, safety, or property.
        </div>
        <div className={styles.privacyheading1}>
        4. Your Choices
        </div>
        <div className={styles.privacytext1}>
        You have the following choices regarding your personal information:
        </div>
        <div className={styles.privacytext1}>
        4.1 Access, Update, or Delete: You can request to access, update, or delete your personal information by 
contacting us.
        </div>
        <div className={styles.privacytext1}>
        4.2 Marketing Communications: You can opt out of receiving marketing communications at any time by 
following the instructions in the communication.
        </div>
        <div className={styles.privacytext1}>
        4.3 Cookies: You can manage cookies through your browser settings or other tools. However, disabling 
cookies may affect the functionality of our website.
        </div>
        <div className={styles.privacyheading1}>
        5. Security
        </div>
        <div className={styles.privacytext1}>
        We take reasonable measures to protect your personal information from unauthorized access, 
disclosure, or alteration. However, no method of transmitting data over the internet is 100% secure.
        </div>
        <div className={styles.privacyheading1}>
        6. Children's Privacy
        </div>
        <div className={styles.privacytext1}>
        Our services are not intended for children under the age of 13. We do not knowingly collect or maintain 
information from children.
        </div>
        <div className={styles.privacyheading1}>
        7. Changes to this Privacy Policy
        </div>
        <div className={styles.privacytext1}>
        We may update this Privacy Policy from time to time. The "Last Updated" date at the beginning of this 
policy will be revised, and we will provide notice of significant changes on our website.
        </div>
        <div className={styles.privacyheading1}>
        8. Contact Us
        </div>
        <div className={styles.privacytext1}>
        If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:
        </div>
        <div className={styles.privacyheading1}>
        STACKVIZ
        </div>
        <div className={styles.privacytext1}>
        1708 Spring Green Blvd Ste 120,
        </div>
        <div className={styles.privacytext1}>
        Katy, TX 77494
        </div>
        <div className={styles.privacytext1}>
        Info@stackviz.com
        </div>
        <div className={styles.privacytext1}>
        1-281-407-1887
        </div>
        <div className={styles.privacytext1} style={{paddingTop:"40px", paddingBottom:"30px"}}>
        Please review this Privacy Policy periodically for any changes. Your continued use of our website or 
services after such changes constitute your acceptance of the updated Privacy Policy.
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default PrivacyPolicy
