import React from "react";
import style from "./OurWork.module.css";
import { BsCircleFill } from "react-icons/bs";
import { ImEye } from "react-icons/im";
import First from "../../Images/firstPro.jpeg";
import Sec from "../../Images/SecPro.jpeg";
import Thr from "../../Images/ThrPro.jpeg";
import blank from "../../Images/blank.png";
import sholo from "../../Images/sholomart.png";
import rentviz from "../../Images/rentviz.png";
import selviz from "../../Images/selviz.png";
// import Four from '../../Images/FourPro.jpeg'
// import Five from '../../Images/FivePro.png'
// import Six from '../../Images/SixPro.jpeg'
// import Seven from '../../Images/SevenPro.jpeg'
// import Eight from '../../Images/EightPro.jpeg'

function OurWork() {
  let data = [
    {
      image: rentviz,
      name: "rentviz",
      link: "http://Rentviz.com/",
    },
    {
      image: selviz,
      name: "Sellviz",
      link: "http://sellviz.com/",
    },

    // {
    //   image: blank,
    //   name: "Boxyoyo",
    //   link: "https://www.boxyoyo.com/",
    // },
    {
      image: sholo,
      name: "Sholmart ",
      link: "https://sholmart.com/",
    },
    // {
    //   image: blank,
    //   name: "Tutorsolo",
    //   link: "http://tutorsolo.com/",
    // },
    // ,
    // {
    //     image: Four
    // },
    // {
    //     image: Five
    // },
    // {
    //     image: Six
    // }
    // ,
    // {
    //     image: Seven
    // }
    // ,
    // {
    //     image: Eight
    // },
  ];
  return (
    <div id="Portfolio" className={style.MainWorkSec}>
      {/* <div className={style.rocketTag}>
                <div className={style.rocketTagStyle}>
                    <BsCircleFill />
                    <div>Our Work</div>
                </div>
            </div> */}
      <div className={style.rocketMainHeading}>PORTFOLIO OF PROJECTS</div>
      <div className={style.DecText}>
        {/* The best way to demonstrate the quality of our work is to show you some
        actual landing pages we have made. Take a look at some great
        conversion-focused landing pages that we created for our clients. */}
      </div>

      <div className={style.OurWorkCard}>
        <div className={style.MainCard}>
          {data.map((items) => (
            <a
              href={items.link}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div className={style.FirstCarImg}>
                <img className={style.CardImg} src={items.image} />
                <div className={style.WebNames}> {items.name}</div>
                <div className={style.cardHoverEffect}>
                  {/* <ImEye size={45} className={style.hoverIcon} /> */}
                  <div className={style.hoverIcon}>More Details</div>
                </div>
              </div>
            </a>
          ))}
          {/* <div className={style.LastCarImg} >
                    <div className={style.LastCardImg}> 
                    <button className={style.SeePro}>
                        See Portfolio
                    </button>
                    </div>

                </div> */}
        </div>
      </div>

      {/* <a href="#Click" style={{ textDecoration: "none" }}>
        <div className={style.WorkButtonStyle}>
          <button className={style.WorkdiscoverCallButton}>
            Book a Discovery Call
          </button>
        </div>
      </a> */}
    </div>
  );
}

export default OurWork;
