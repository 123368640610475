import React from 'react'

function Portfolios() {
  return (
    <div>
       No Content Available
    </div>
  )
}

export default Portfolios
