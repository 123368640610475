import React from "react";
import style from "./WhatWeDo.module.css";
import { ImArrowRight, ImArrowDown } from "react-icons/im";
import one from "../../Images/WhatOne.png";
import two from "../../Images/WhatTwo.png";
import three from "../../Images/WhatThree.png";
import four from "../../Images/WhatFour.png";

function WhatWeDo() {
  return (
    <div className={style.MainSecWhat}>
      {/* <div className={style.rocketTag}>
            <div className={style.rocketTagStyle}>
        <BsCircleFill/>
        <div>What We Do</div>
        </div>
        </div> */}

      <div className={style.MainSectionWorking}>
        <div className={style.WhatMainHeading}>
          We create web and mobile apps that solve people’s problems using
          human-centered design principles.
          <div className={style.rightArrow}>
            <ImArrowRight />
          </div>
          <div className={style.downArrow}>
            <ImArrowDown />
          </div>
        </div>

        <div className={style.ListContent}>
          <div className={style.fonts}>
            <div>Product Strategy</div>
            <div>Product Design</div>
            <div>Product Development</div>
            <div>Product Launch</div>
            <div>Product Growth</div>
            <div>Product Data Management</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
