import React from 'react'
import style from "./MainPage.module.css"

import { FaBezierCurve } from 'react-icons/fa';
import { FaFunnelDollar } from 'react-icons/fa';
import { FaFlask } from 'react-icons/fa';
import FirstSection from '../FirstSection/FirstSection';
import RocketPage from '../RocketPage/RocketPage';
import WhatWeDo from '../WhatWeDo/WhatWeDo';
import OurWork from '../OurWork/OurWork';
import ScopOfWork from '../ScopOfWork/ScopOfWork';
import Footer from '../Footer/Footer';
import Testimonial from '../Testimonial/Testimonial';
import ClientReview from '../ClientReview/ClientReview';
import NavBarMain from '../NavBar/NavBarMain';

function MainPage() {
    return (
        <div className={style.MainPage}>
            {/* <div className={style.TopAlert}>
                <p>
                    Thanks! You'll recive it in your inbox in few minutes.
                </p>
                <p>
                    Meanwhile.. Have a look at our nice agency's website 👇👇
                </p>
            </div> */}
            {/* <FirstSection/> */}
            <NavBarMain/>
            <RocketPage/>
            <WhatWeDo/>
            <OurWork/>
           
            {/* <ClientReview/> */}
            <Footer/>
        </div>
    )
}

export default MainPage
