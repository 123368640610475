import React from 'react'
import styles from './TermCondition.module.css'
import NavBarMain from '../NavBar/NavBarMain'
import Footer from '../Footer/Footer'
const TermsCondition = () => {
  return (
    <>
    <NavBarMain/>
    <div className={styles.privacyMain}>
      <div className={styles.privacyContainer}>
        <div className={styles.privacyMainheading}>
        Terms and Conditions 

        </div>
       
        <div className={styles.privacyMaintext}>
        These Terms and Conditions ("Agreement") are entered into by and between STACKVIZ, hereinafter 
referred to as the "Company," and the client or entity that engages the technical services of the 
Company, hereinafter referred to as the "Client." This Agreement outlines the terms and conditions 
governing the provision of technical services by the Company
        </div>

        <div className={styles.privacyheading1}>
        1. Engagement of Services:
        </div>
        <div className={styles.privacytext1}>
        1.1. The Client engages the Company to provide technical services as described in the Scope of Work 
document or contract.
        </div>
        <div className={styles.privacytext1}>
        1.2. The Client shall provide all necessary cooperation and information to enable the Company to fulfill 
its obligations.
        </div>
      
        <div className={styles.privacyheading1}>
        2. Scope of Work:

        </div>
        <div className={styles.privacytext1}>
        2.1. The specific details and objectives of the project will be outlined in the Scope of Work document 
or contract.

        </div>
        <div className={styles.privacytext1}>
        2.2. Any changes to the Scope of Work must be agreed upon in writing by both parties.
        </div>
       
        <div className={styles.privacyheading1}>
        3. Fees and Payments:
        </div>
        <div className={styles.privacytext1}>
        3.1. The Client agrees to pay the Company the fees as outlined in the contract or invoice, in accordance 
with the agreed payment schedule
        </div>
        <div className={styles.privacytext1}>
        3.2. Late payments may incur interest charges at a rate of 2.5% per month.
        </div>
        <div className={styles.privacytext1}>
        3.3. The Client is responsible for all expenses and costs associated with the project, as specified in the 
Scope of Work.        </div>
        <div className={styles.privacyheading1}>
        4. Confidentiality:

        </div>
        <div className={styles.privacytext1}>
        4.1. The Company agrees to maintain the confidentiality of all information and data provided by the 
Client and will not disclose it to third parties without the Client's consent.

        </div>
        <div className={styles.privacytext1}>
        4.2. The Client agrees to maintain the confidentiality of any proprietary methodologies, processes, or 
tools used by the Company
        </div>
        <div className={styles.privacyheading1}>
        5. Intellectual Property:
        </div>
        <div className={styles.privacytext1}>
        5.1. Any work created or developed by the Company during the engagement shall be the intellectual 
property of the Client.
        </div>
        <div className={styles.privacytext1}>
        5.2. The Client may grant the Company a non-exclusive, irrevocable, worldwide license to use such 
intellectual property for portfolio and promotional purposes

        </div>
        <div className={styles.privacyheading1}>
        6. Termination:
        </div>
        <div className={styles.privacytext1}>
        6.1. Either party may terminate this Agreement in the event of a material breach by the other party, 
provided written notice is given.
        </div>
        <div className={styles.privacytext1}>
        6.2. The Client may terminate the Agreement by providing the Company with a written notice of 
[Termination Notice Period] days.
        </div>
        <div className={styles.privacyheading1}>
        7. Liability and Indemnification:
        </div>
        <div className={styles.privacytext1}>
        7.1. The Company's liability is limited to the total fees paid by the Client.
        </div>
        <div className={styles.privacytext1}>
        7.2. The Client agrees to indemnify and hold the Company harmless from any claims, damages, or 
liabilities arising out of the use of the services or products developed.
        </div>
        <div className={styles.privacyheading1}>
        8. Dispute Resolution:

        </div>
        <div className={styles.privacytext1}>
        8.1. Any disputes arising under this Agreement shall be resolved through negotiation or, if necessary, 
through legal action in accordance with the laws of the jurisdiction in which the Company is based.
        </div>
        <div className={styles.privacyheading1}>
        9. Governing Law:

        </div>
        <div className={styles.privacytext1}>
        9.1. This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction 
in which the Company is based.
        </div>
        <div className={styles.privacyheading1}>
        10. Entire Agreement:

        </div>
        <div className={styles.privacytext1}>
        10.1. This Agreement constitutes the entire understanding between the parties and supersedes all 
prior agreements, whether written or oral.

        </div>
        <div className={styles.privacytext1} style={{paddingTop:"40px", paddingBottom:"20px"}}>
        By engaging the services of the Company, the Client acknowledges that they have read and understood 
these Terms and Conditions and agree to be bound by them.

        </div>
        <div className={styles.privacyheading1}>
        STACKVIZ
        </div>
        <div className={styles.privacytext1}>
        1708 Spring Green Blvd Ste 120,
        </div>
        <div className={styles.privacytext1}>
        Katy, TX 77494
        </div>
        <div className={styles.privacytext1}>
        Info@stackviz.com
        </div>
        <div className={styles.privacytext1}>
        1-281-407-1887
        </div>
        <div className={styles.privacytext1}>
        [Date]

        </div>
        <div className={styles.privacytext1}>
        Client's Signature: __________________________ 

        </div>
        <div className={styles.privacytext1}  style={{paddingBottom:"30px"}}>
        Company's Signature: __________________________
        </div>
        
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default TermsCondition
