import React from 'react'
import { BsCircleFill } from 'react-icons/bs';
import Design from '../../Images/design.jpeg'
import development from '../../Images/development.jpeg'
import Line from '../../Images/Line.png'
import marketing from '../../Images/marketing.jpeg'
import creatives from '../../Images/creatives.png'

import style from './ScopOfWork.module.css'
function ScopOfWork() {
    return (
        <>
            <div className={style.MainScopeCont}>
                <div className={style.rocketTag}>
                    <div className={style.rocketTagStyle}>
                        <BsCircleFill />
                        <div>Services</div>
                    </div>
                </div>

                <div className={style.ScopeHeadCont}>
                    <div className={style.ScopeHeading}>
                    StackViz provides the following services for web and mobile apps
                    </div>
                </div>
            </div>


            <div className={style.ScopeImgText}>

                <div className={style.ScopeImg}>
                    <img className={style.ScopeImgSize} src={Design} />

                    <div className={style.ScopeText}>
                        <div className={style.StylingText}>
                        <span className={style.spanStyle}>
                            Design
                        </span>
                        <img className ={style.FirstDash}  src={Line}/>
                        <ul >
                            <li className={style.ScopeUl}>
                            Responsive website, app, and mobile designs
                            </li>
                            <li className={style.ScopeUl}>
                            Native, hybrid, Android, and iOS app designs
                            </li>
                            <li className={style.ScopeUl}>
                            UI/UX strategy, research, and design

                            </li>
                        </ul>
                        </div>
                    </div>
                </div>




                <div className={style.ScopeSecImg}>
                    <img className={style.ScopeSecImgSize} src={development} />

                    <div className={style.ScopeText}>
                        <div className={style.StylingText}>
                        <span className={style.spanStyle}>
                            Development
                        </span>
                        <img  className ={style.SecDash} src={Line}/>
                        <ul >
                            <li className={style.ScopeUl}>
                            Responsive website, app, and mobile Development
                            </li>
                            <li className={style.ScopeUl}>
                            Native, hybrid, Android, and iOS app development

                            </li>
                            <li className={style.ScopeUl}>
                            Software development

                            </li>
                            <li className={style.ScopeUl}>
                            UI/UX development


                            </li>
                            <li className={style.ScopeUl}>
                            Machine learning and deep learning applications

                            </li>
                        </ul>
                        </div>
                    </div>
                </div>







                <div className={style.ScopeImg}>
                    <img className={style.ScopeMarImgSize} src={marketing} />

                    <div className={style.ScopeText}>
                        <div className={style.StylingText}>
                        <span className={style.spanStyle}>
                            Marketing
                        </span>
                        <img  className ={style.ThrDash} src={Line}/>
                        <ul >
                            <li className={style.ScopeUl}>
                            Content strategy and development
                            </li>
                            <li className={style.ScopeUl}>
                            Social media marketing
                            </li>
                            <li className={style.ScopeUl}>
                            Social media advertising
                            </li>
                            <li className={style.ScopeUl}>
                            Search Engine Optimization (SEO)
                            </li>
                            <li className={style.ScopeUl}>
                            Search Engine Marketing (SEM)
                            </li>
                            <li className={style.ScopeUl}>
                            Display advertising
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>




                <div className={style.ScopeSecImg}>
                    <img className={style.ScopeSecImgSize} src={creatives} />

                    <div className={style.ScopeText}>
                        <div className={style.StylingText}>
                        <span className={style.spanStyle}>
                        Creatives
                        </span>
                        <img  className ={style.FrthDash} src={Line}/>
                        <ul >
                            <li className={style.ScopeUl}>
                            Logo and wordmark designs                 
                            </li>
                            <li className={style.ScopeUl}>
                            Graphic and brand designs

                            </li>
                            <li className={style.ScopeUl}>
                            Photo editing


                            </li>
                            <li className={style.ScopeUl}>
                            Video editing

                            </li>
                            <li className={style.ScopeUl}>
                            Copywriting

                            </li>
                        </ul>
                        </div>
                    </div>
                </div>


                <div className={style.ScopSecButton}>

                    <div className={style.ScopButtonUpperText}>Think we can help?</div>
                    <button className={style.WorkdiscoverCallButton}>
                Book a Discovery Call
            </button>
                </div>











            </div>
        </>
    )
}

export default ScopOfWork